.projects {

	@media (max-width: 600px)  { 
		.project-list {
			display: flex !important;
			flex-direction: column;
		}
	}

	.project-list {
		display: grid;
		gap: 1rem;
		grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

		margin-top: 80px;

		.project-detail {
			//border: 1px solid white;
			border-radius: 8px;
			overflow: hidden;
			position: relative;

			&:hover {

				img {
					opacity: 0.3;
				}

				.middle,
				i.devicon-github-original {
					opacity: 1;
				}
				
			}

			img {
				width: 100%;
				height: 100%;

				opacity: 1;
				display: block;
				transition: .5s ease;
				backface-visibility: hidden;
			}

			.middle {
				transition: .5s ease;
				opacity: 0;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				text-align: center;

				.text {
					transition: .5s ease;
					border: 2px white solid;
					border-radius: 4px;
					color: white;
					font-size: 16px;
					padding: 16px 32px;
					font-weight: bold;
					cursor: pointer;

					&:hover {
						background-color: white;
						color: black;
					}
				}

				.description {
					margin-top: 8px;
					font-size: 12px;
				}
			}

			i.devicon-github-original {
				position: absolute;
				bottom: 8px;
				left: 8px;
				font-size: 30px;

				transition: .5s ease;
				opacity: 0;

				cursor: pointer;
			}

			.tech-stack {
				background-color: rgba(0, 0, 0, 0.7);
				padding: 8px 6px;
				border-radius: 8px;
				position: absolute;
				bottom: 8px;
				right: 8px;

				display: flex;
				align-items: center;
				justify-content: center;

				.technology:not(:last-child) {
					margin-right: 8px;
				}
			}
		}
	}
}