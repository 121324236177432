.App {
	overflow-x: hidden;
}

header {
	display: flex;
	justify-content: space-between;
	padding: 16px;

	.title {
		font-weight: 400;
		font-size: 20px;
		letter-spacing: 0.02em;
		text-overflow: ellipsis;
		white-space: nowrap;
		vertical-align: top;
		margin: 0px 0px 0px 8px;
		overflow: hidden;
	}

	.actions {
		display: flex;

		a {
			margin-left: 8px;
			text-decoration: none;
			font-weight: bold;
			color: #fff;
			text-transform: uppercase;
		}
	}
}

section {
	min-height: 100vh;
	background-color: rgb(33, 33, 33);
	color: #fff;
}

section.about {
	background-color: #fff;
	color: #000;
}

section.about, section.projects {
	padding: 50px 25px;

	.section-header {
		text-align: center;
		font-size: 30px;
	}

	.content {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: 40px;

		@media (max-width: 768px) {
			flex-direction: column;
		}
	}
}